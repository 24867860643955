const OurAdvantages = () => {
  return (
    <section className="about">
      <h2 className="about-title title">С какво е полезна нашата услуга?</h2>
      <div className="about-content">
        <div className="about-advantages">
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Решава непосилни за вас задачи.</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Печели ви време.</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Спестява средства.</span>
          </div>
        </div>
        <div className="about-advantages">
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Лесна и удобна за използване.</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Помага при разпределение на задачите.</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Намалява вашите ангажименти.</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurAdvantages;
