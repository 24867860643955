import useInput from "../hooks/useInput";
import "./Contacts.css";
import { useState, useRef } from "react";
import {
  notify,
  error,
  success,
  validateEmpty,
  requiredMsg,
  emailJsUrl,
} from "../util/util";

const Contacts = () => {
  const [isSending, setIsSending] = useState(false);
  const toastId = useRef(null);

  const {
    value: enteredFullName,
    isValid: fullNameIsValid,
    hasError: fullNameHasError,
    valueChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHandler,
    setIsTouched: fullNameIsTouched,
    reset: resetFullName,
  } = useInput(validateEmpty);

  const {
    value: enteredPhone,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    setIsTouched: phoneIsTouched,
    reset: resetPhone,
  } = useInput(validateEmpty);

  const {
    value: enteredEmail,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    setIsTouched: emailIsTouched,
    reset: resetEmail,
  } = useInput(validateEmpty);

  const {
    value: enteredMsg,
    isValid: msgIsValid,
    hasError: msgHasError,
    valueChangeHandler: msgChangeHandler,
    inputBlurHandler: msgBlurHandler,
    setIsTouched: msgIsTouched,
    reset: resetMsg,
  } = useInput((value) => {
    return validateEmpty(value) && value.trim().length >= 10;
  });

  const contactsSubmitHandler = async (e) => {
    e.preventDefault();

    if (!fullNameIsValid || !phoneIsValid || !emailIsValid || !msgIsValid) {
      fullNameIsTouched(true);
      phoneIsTouched(true);
      emailIsTouched(true);
      msgIsTouched(true);
      return;
    }

    const data = {
      service_id: "service_hk9k4uo",
      template_id: "contact_template",
      user_id: "p4fGMaz1r5Ovca795",
      template_params: {
        fullName: enteredFullName,
        phone: enteredPhone,
        email: enteredEmail,
        msg: enteredMsg,
      },
    };

    setIsSending(true);
    notify(toastId);

    fetch(emailJsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Възникна грешка!");
        }
        success(toastId);
      })
      .catch((err) => {
        error(toastId);
      })
      .finally(() => {
        resetFullName();
        resetPhone();
        resetEmail();
        resetMsg();
        setIsSending(false);
      });
  };

  return (
    <div className="page-container">
      <h1 className="page-title title">Контакти</h1>
      <div className="contacts-wrapper">
        <div className="contacts-content">
          <div className="contact-content">
            <h3>Телефон</h3>
            <div className="contact-control">
              <ion-icon class="icon" name="call-outline"></ion-icon>
              <a href="tel:0877292226">087 729 2226</a>
            </div>
          </div>
          <div className="contact-content">
            <h3>Имейл</h3>
            <div className="contact-control">
              <ion-icon class="icon" name="mail-open-outline"></ion-icon>
              <a href="mailto:CleanDirtybg@gmail.com">CleanDirtybg@gmail.com</a>
            </div>
          </div>
          <div className="contact-content">
            <h3>Социални мрежи</h3>
            <div className="contact-control">
              <ion-icon
                class="icon"
                name="logo-facebook"
                style={{ color: "rgb(8,102,255)" }}
              ></ion-icon>
              <a href="https://www.facebook.com/CleanDirtybg">CleanDirtybg</a>
            </div>
            <div className="contact-control">
              <ion-icon
                class="icon"
                name="logo-youtube"
                style={{ color: "rgb(255,0,0)" }}
              ></ion-icon>
              <a href="https://www.youtube.com/@cleandirtybg">cleandirty</a>
            </div>
            <div className="contact-control">
              <ion-icon class="icon" name="logo-instagram"></ion-icon>
              <a href="https://www.instagram.com/CleanDirtybg">CleanDirtybg</a>
            </div>
          </div>
        </div>
        <div className="contacts-form">
          <h2 className="form-title">Направете запитване</h2>
          <form onSubmit={contactsSubmitHandler}>
            <div className="form-control">
              <label htmlFor="fullName">Име и фамилия</label>
              <input
                className={fullNameHasError ? "invalid-input" : ""}
                type="text"
                name="fullName"
                value={enteredFullName}
                onChange={fullNameChangeHandler}
                onBlur={fullNameBlurHandler}
              />
              {fullNameHasError && requiredMsg}
            </div>
            <div className="form-control">
              <label htmlFor="phone">Телефон</label>
              <input
                className={phoneHasError ? "invalid-input" : ""}
                type="text"
                name="phone"
                value={enteredPhone}
                onChange={phoneChangeHandler}
                onBlur={phoneBlurHandler}
              />
              {phoneHasError && requiredMsg}
            </div>
            <div className="form-control">
              <label htmlFor="email">Имейл</label>
              <input
                className={emailHasError ? "invalid-input" : ""}
                type="email"
                name="email"
                value={enteredEmail}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
              />
              {emailHasError && requiredMsg}
            </div>
            <div className="form-control">
              <label htmlFor="msg">Запитване</label>
              <textarea
                className={msgHasError ? "invalid-input" : ""}
                name="msg"
                rows="6"
                value={enteredMsg}
                onChange={msgChangeHandler}
                onBlur={msgBlurHandler}
              ></textarea>
              {msgHasError && (
                <p className="invalid-message">
                  Полето трябва да съдържа поне 10 символа!
                </p>
              )}
            </div>

            <div className="form-control form-control-submit">
              <button className="form-submit" disabled={isSending}>
                Изпрати
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
