import "./OurTarget.css";

export default function OurTarget() {
  return (
    <section className="our-target">
      <p>
        Ние от <span className="emphasize">CleanDirty</span> имаме за цел, да
        доставим възможно най-много стойност на всички наши клиенти. Стараем се
        да бъдем един практичен помощник, който да разбира и решава вашите
        проблеми.
      </p>

      <p>
        Знаем, че за нашата услуга най-важно е доверието, за което сме готови да
        се борим и градим заедно.
      </p>
      <p>
        Ако град <span className="emphasize">Варна</span> е вашият дом и
        разчитате на качество, доверие и повече решени проблеми е време да се
        свържете с нас !
      </p>
    </section>
  );
}
