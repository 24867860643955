import "./ImagePopup.css";

const ImagePopup = ({ imageSrc, closeImagePopup }) => {
  const popupClickHandler = (e) => {
    if (e.target.getAttribute("name") === "close-popup") {
      closeImagePopup();
    }
  };

  return (
    <div
      name="close-popup"
      className="image-popup-container"
      onClick={popupClickHandler}
    >
      <img className="image" src={imageSrc} alt="" />
      <ion-icon
        onClick={() => {
          closeImagePopup();
        }}
        class="close-popup"
        name="close-circle"
      ></ion-icon>
    </div>
  );
};

export default ImagePopup;
