import { useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const menuClickHandler = (e) => {
    if (e.target.nodeName === "A" || e.target.id === "close-menu") {
      setIsMenuOpened(false);
      return;
    }
    if (e.target.id === "open-menu") {
      setIsMenuOpened(true);
      return;
    }
  };
  return (
    <nav className="main-navbar">
      <div className="nav-logo-container">
        <NavLink className="nav-logo" to={"/"}></NavLink>
      </div>
      <div className="nav-links">
        <div className="main-links">
          <NavLink className="nav-link" to={"/"}>
            Начало
          </NavLink>
          <NavLink className="nav-link" to={"/gallery"}>
            Галерия
          </NavLink>
          <NavLink className="nav-link" to={"/services"}>
            Услуги
          </NavLink>
          <NavLink className="nav-link" to={"/contacts"}>
            Контакти
          </NavLink>
        </div>
        <div
          onClick={menuClickHandler}
          className={`menu-min ${isMenuOpened ? "opened" : "closed"}`}
        >
          <NavLink className="nav-link" to={"/"}>
            Начало
          </NavLink>
          <NavLink className="nav-link" to={"/gallery"}>
            Галерия
          </NavLink>
          <NavLink className="nav-link" to={"/services"}>
            Услуги
          </NavLink>
          <NavLink className="nav-link" to={"/contacts"}>
            Контакти
          </NavLink>
          <ion-icon
            id="close-menu"
            class="close-menu"
            name="close-circle-outline"
          ></ion-icon>
        </div>
        <ion-icon
          onClick={menuClickHandler}
          class="icon icon-menu"
          name="menu-outline"
          id="open-menu"
        ></ion-icon>
      </div>
    </nav>
  );
};

export default Navbar;
