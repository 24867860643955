import "./About.css";

const About = () => {
  return (
    <section className="about">
      <h2 className="about-title title">Защо да изберете нас?</h2>
      <div className="about-content">
        <div className="about-advantages">
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Поставяме Вас на първо място!</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Получавате помощник на когото може да се доверите.</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Грижим се за вас и вашето здраве.</span>
          </div>
        </div>
        <div className="about-advantages">
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Наслаждавате се на качеството.</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Получавате 24 часа гаранция.</span>
          </div>
          <div className="advantage">
            <ion-icon class="icon" name="checkmark-outline"></ion-icon>
            <span>Получавате безплатен оглен и консултация.</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
