import { toast } from "react-toastify";

const emailJsUrl = "https://api.emailjs.com/api/v1.0/email/send";
const requiredMsg = <p className="invalid-message">Полето е задължително!</p>;

const notify = (toastId) =>
  (toastId.current = toast("Изпращане...", {
    autoClose: false,
    isLoading: true,
  }));

const error = (toastId) =>
  toast.update(toastId.current, {
    render: "Възникна грешка!",
    type: toast.TYPE.ERROR,
    autoClose: 5000,
    isLoading: false,
  });

const success = (toastId) =>
  toast.update(toastId.current, {
    render: "Изпратено!",
    type: toast.TYPE.SUCCESS,
    autoClose: 5000,
    isLoading: false,
  });

const validateEmpty = (value) => {
  return value.trim() !== "";
};

export { notify, error, success, validateEmpty, requiredMsg, emailJsUrl };
