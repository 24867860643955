import About from "../components/About";
import OurTarget from "../components/OurTarget";
import OurAdvantages from "../components/UI/OurAdvantages";
import ServicesPreview from "../components/services/ServicesPreview";
import "./Home.css";

const Home = () => {
  const services = [
    {
      id: 1,
      title: "Основно почистване",
      img: require("../img/services/1.webp"),
    },
    {
      id: 5,
      title: "Абонаментно почистване",
      img: require("../img/services/7.webp"),
    },
    {
      id: 2,
      title: "Почистване на прозорци",
      img: require("../img/services/4.webp"),
    },
    {
      id: 3,
      title: "Почистване след ремонт",
      img: require("../img/services/6.webp"),
    },
    {
      id: 4,
      title: "Пране на мека мебел",
      img: require("../img/services/5.webp"),
    },

    {
      id: 6,
      title: "Услуги по желание на клиента",
      img: require("../img/services/3.webp"),
    },
    {
      id: 7,
      title: "Комплект за почистване след ремонт",
      img: require("../img/services/2.webp"),
    },
  ];

  return (
    <div className="page-container">
      <div className="hero">
        <div className="hero-title">Clean Dirty</div>
        <a href="tel:0877292226" className="hero-text">
          <span>Обади се сега</span>
        </a>
      </div>

      <OurTarget />
      <OurAdvantages />
      <About />
      <ServicesPreview services={services} />
    </div>
  );
};

export default Home;
