import "./ContactsLine.css";

const ContactsLine = () => {
  return (
    <div className="contacts">
      <div className="contact-fragment">
        <a className="contact-info" href="tel:0877292226">
          <ion-icon class="icon" name="call-outline"></ion-icon>
          <span>087 729 2226</span>
        </a>
        <a
          className="contact-info"
          href="mailto: 
CleanDirtybg@gmail.com"
        >
          <ion-icon class="icon" name="mail-open-outline"></ion-icon>{" "}
          <span>CleanDirtybg@gmail.com</span>
        </a>
      </div>

      <div className="contact-fragment">
        <a
          className="contact-info"
          href="https://www.facebook.com/CleanDirtybg"
        >
          <ion-icon
            class="icon"
            name="logo-facebook"
            style={{ color: "rgb(8,102,255)" }}
          ></ion-icon>
        </a>
        <a
          className="contact-info"
          href="https://www.youtube.com/@cleandirtybg"
        >
          <ion-icon
            class="icon"
            name="logo-youtube"
            style={{ color: "rgb(255,0,0)" }}
          ></ion-icon>
        </a>
        <a
          className="contact-info"
          href="https://www.instagram.com/CleanDirtybg/"
        >
          <ion-icon class="icon" name="logo-instagram"></ion-icon>
        </a>
      </div>
    </div>
  );
};

export default ContactsLine;
