import { useNavigate } from "react-router-dom";
import "./ServicesPreview.css";

const ServicesPreview = ({ services }) => {
  const navigate = useNavigate();
  const serviceClickHandler = (id) => {
    navigate("/services/" + id);
  };

  return (
    <section className="services-preview">
      {services.map((service) => {
        return (
          <div
            onClick={() => serviceClickHandler(service.id)}
            key={service.id}
            className="service-preview"
          >
            <h3 className="services-title service-preview-title title">
              {service.title}
            </h3>
            <div className="img-container">
              <img src={service.img} alt="" className="service-img" />
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ServicesPreview;
