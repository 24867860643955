import useInput from "../../hooks/useInput";
import "./ReserveModal.css";
import {
  validateEmpty,
  requiredMsg,
  emailJsUrl,
  error,
  notify,
  success,
} from "../../util/util";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

const invalidDateMsg = <p className="invalid-message">Невалидна дата.</p>;

export default function ReserveModal({ onClose, title }) {
  const toastId = useRef(null);

  const reservedNotify = (toastId) => {
    toast.update(toastId.current, {
      style: { fontSize: "1.4rem" },
      render:
        "Очаквайте обаждане от наш служител за потвърждение и допълнително информация.",
      type: toast.TYPE.SUCCESS,
      autoClose: false,
      isLoading: false,
      position: "top-center",
    });
  };

  const [isSending, setIsSending] = useState(false);

  const {
    value: firstName,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    setIsTouched: firstNameSetIsTouched,
    reset: firstNameReset,
  } = useInput(validateEmpty);

  const {
    value: lastName,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    setIsTouched: lastNameSetIsTouched,
    reset: lastNameReset,
  } = useInput(validateEmpty);

  const {
    value: phone,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    setIsTouched: phoneSetIsTouched,
    reset: phoneReset,
  } = useInput(validateEmpty);

  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    setIsTouched: emailSetIsTouched,
    reset: emailReset,
  } = useInput(validateEmpty);

  const {
    value: date,
    isValid: dateIsValid,
    hasError: dateHasError,
    valueChangeHandler: dateChangeHandler,
    inputBlurHandler: dateBlurHandler,
    setIsTouched: dateSetIsTouched,
    reset: dateReset,
  } = useInput((value) => {
    return new Date(value) >= new Date().setHours(0, 0, 0, 0);
  });

  const {
    value: convenientTime,
    isValid: convenientTimeIsValid,
    hasError: convenientTimeHasError,
    valueChangeHandler: convenientTimeChangeHandler,
    inputBlurHandler: convenientTimeBlurHandler,
    setIsTouched: convenientTimeSetIsTouched,
    reset: convenientTimeReset,
  } = useInput(validateEmpty);

  const modalCloseHandler = (e) => {
    if (
      e.target.classList.contains("reserve-modal") ||
      e.target.id === "reject"
    ) {
      e.preventDefault();
      onClose();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      !firstNameIsValid ||
      !lastNameIsValid ||
      !phoneIsValid ||
      !emailIsValid ||
      !dateIsValid ||
      !convenientTimeIsValid
    ) {
      firstNameSetIsTouched(true);
      lastNameSetIsTouched(true);
      phoneSetIsTouched(true);
      emailSetIsTouched(true);
      dateSetIsTouched(true);
      convenientTimeSetIsTouched(true);

      return;
    }

    const data = {
      service_id: "service_hk9k4uo",
      template_id: "reserve_template",
      user_id: "p4fGMaz1r5Ovca795",
      template_params: {
        service: title,
        fullName: `${firstName} ${lastName}`,
        phone: phone,
        email: email,
        date: date,
        time: convenientTime,
      },
    };

    setIsSending(true);
    notify(toastId);

    fetch(emailJsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Възникна грешка!");
        }
        reservedNotify(toastId);
      })
      .catch((err) => {
        error(toastId);
      })
      .finally(() => {
        onClose();
        firstNameReset();
        lastNameReset();
        phoneReset();
        emailReset();
        dateReset();
        convenientTimeReset();

        setIsSending(false);
      });
  };

  return (
    <div onClick={modalCloseHandler} className="reserve-modal">
      <form onSubmit={submitHandler} className="reserve-form">
        <h4 className="modal-title">{title}</h4>
        <div className="form-control-modal">
          <label htmlFor="firstName">Име</label>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={firstNameChangeHandler}
            onBlur={firstNameBlurHandler}
          />
          {firstNameHasError && requiredMsg}
        </div>
        <div className="form-control-modal">
          <label htmlFor="lastName">Фамилия</label>
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={lastNameChangeHandler}
            onBlur={lastNameBlurHandler}
          />
          {lastNameHasError && requiredMsg}
        </div>
        <div className="form-control-modal">
          <label htmlFor="phone">Телефон</label>
          <input
            type="text"
            name="phone"
            value={phone}
            onChange={phoneChangeHandler}
            onBlur={phoneBlurHandler}
          />
          {phoneHasError && requiredMsg}
        </div>
        <div className="form-control-modal">
          <label htmlFor="email">Имейл</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
          />
          {emailHasError && requiredMsg}
        </div>
        <div className="form-control-modal">
          <label htmlFor="date">Дата</label>
          <input
            type="date"
            name="date"
            value={date}
            onChange={dateChangeHandler}
            onBlur={dateBlurHandler}
          />
          {dateHasError && invalidDateMsg}
        </div>
        <div className="form-control-modal convenient-time-default">
          <label htmlFor="convenientTime">Удобен час</label>
          <select
            name="convenientTime"
            value={convenientTime}
            onChange={convenientTimeChangeHandler}
            onBlur={convenientTimeBlurHandler}
          >
            <option className="" disabled value="">
              Изберете удобен за вас час
            </option>
            <option value="08:00-11:00">08:00ч. - 11:00ч.</option>
            <option value="11:00-14:00">11:00ч. - 14:00ч.</option>
            <option value="14:00-18:00">14:00ч. - 18:00ч.</option>
          </select>
          {convenientTimeHasError && requiredMsg}
        </div>
        <div className="form-control-modal form-controls">
          <button disabled={isSending} className="submit-btn">
            Изпрати
          </button>
          <button disabled={isSending} id="reject" className="reject-btn">
            Откажи
          </button>
        </div>
      </form>
    </div>
  );
}
