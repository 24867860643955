import { useState } from "react";
import ReserveModal from "./ReserveModal";

export default function Service({
  children,
  serviceRef,
  image,
  title,
  priceAsText,
}) {
  const [showReserveModal, setShowReserveModal] = useState(false);

  const reserveHandler = () => {
    setShowReserveModal(true);
  };

  const closeModal = () => {
    setShowReserveModal(false);
  };

  return (
    <>
      <div ref={serviceRef} className="service-content">
        <div className="service-img-wrapper">
          <img src={image} alt="Service" className="service-img" />
        </div>
        <div className="service-text-wrapper">
          <h3 className="services-title service-title title">{title}</h3>
          {children}
          <div className="service-controls">
            <span className="service-price">{priceAsText}</span>
            <p onClick={reserveHandler} className="service-reserve">
              Запази
            </p>
          </div>
        </div>
      </div>
      {showReserveModal && <ReserveModal onClose={closeModal} title={title} />}
    </>
  );
}
